import { utility } from "../../../helpers/Utility";

const moment = require("moment-timezone");
const timezone = moment.tz.guess();
const now = moment.tz(new Date(), utility.timezone).tz(timezone).format();
const internet_url =
  "https://dev.arenaplus.fastcloudnetwork.com/api/proxy?url=";

export const config = (channel) => ({
  file: {
    hlsOptions: {
      xhrSetup: (xhr, url) =>
        channel.stream_path &&
        xhr.setRequestHeader("custom", channel.stream_path),
    },
  },
});

export const timeInterval = (epg, player) => {
  // if (player.current) {
  //   console.log(
  //     "TIME INTERVAL FUNCTION CURRENT TIME ===  ",
  //     player.current.getCurrentTime()
  //   );
  // } else {
  //   console.log("player.current = undefined");
  // }
  return moment
    .tz(epg.start, utility.timezone)
    .add(
      player.current && player.current.getCurrentTime()
        ? player.current.getCurrentTime()
        : 0,
      "seconds"
    )
    .tz(timezone)
    .format("HH:mm:ss");
};

export const printTime = (epg) =>
  moment.tz(epg.end, utility.timezone).tz(timezone).format("HH:mm:ss");

export const streaming = (channel, epgs, epg) => {
  if (channel.tutorial) return channel.tutorial;
  if (channel.stream_path && 
    ! (channel.stream_path.includes("str.exyu.cc") || channel.stream_path.includes("streamer.exyu.cc"))) {
    if (
      channel.stream_path &&
      (channel.stream_path.includes("srv1.exyu.cc") ||
        //channel.stream_path.includes("line.4k-sino.com"))
        channel.stream_path.includes("line."))
    ) {
      if (channel.stream_path.includes(".m3u8")) {
        return channel.stream_path;
      }
      return generateHlsUrl(channel.stream_path);
    }

    return `${channel.stream_path}`;
    // return `${internet_url}${channel.stream_path}`;
  } else {
    let previousEpgs =
      epgs && epgs.length
        ? epgs.filter(
            (item) =>
              moment.tz(item.end, utility.timezone).tz(timezone).format() < now
          )
        : [];
    if (epg && previousEpgs.includes(epg) && channel.tv_archive_duration > 0)
      return epg.src + `?token=${localStorage.getItem("stream_token")}`;
    else {
      if (channel.current_epg && channel.current_epg.src) {
        return `${channel.current_epg.src}?token=${localStorage.stream_token}`;
      }
      if (channel.stream_path && 
        (channel.stream_path.includes("str.exyu.cc") || channel.stream_path.includes("streamer.exyu.cc"))
        ) {
          if(channel.stream_path.includes("token=")) return channel.stream_path

          return `${channel.stream_path}?token=${localStorage.stream_token}`;
        }

      return `${channel.src}?token=${localStorage.stream_token}`;
    }
  }
};

const generateHlsUrl = (url) => {
  const indexOfSlash = url.indexOf("/", 8);
  return (
    url.slice(0, indexOfSlash) + "/live" + url.slice(indexOfSlash) + ".m3u8"
  );
};
